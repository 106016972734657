<template>
    <div class="tabDefult-col newsLetterTab">
        <router-link :to="{ name : bbs.link1, params : { idx:bbs.idx }}"><button class="tab-default on" @click="bbs.ClickTab(1, $event)">사보 전체</button></router-link>
        <router-link :to="{ name : bbs.link2, params : { idx:bbs.idx }}"><button class="tab-default" @click="bbs.ClickTab(2, $event)">CEO<br>Message</button></router-link>
        <router-link :to="{ name : bbs.link3, params : { idx:bbs.idx }}"><button class="tab-default" @click="bbs.ClickTab(3, $event)">Global<br>Partners</button></router-link>
        <router-link :to="{ name : bbs.link4, params : { idx:bbs.idx }}"><button class="tab-default" @click="bbs.ClickTab(4, $event)">Program<br>Report</button></router-link>
        <router-link :to="{ name : bbs.link5, params : { idx:bbs.idx }}"><button class="tab-default" @click="bbs.ClickTab(5, $event)">Meet The<br>Teachers</button></router-link>
    </div>
</template>
<script>
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'

export default {
    name: 'NewsLetterTab',
    props: {
        idx: {
            type: Number,
            default: 0,
        },
        tidx: {
            type: Number,
            default: 0,
        },
        mode: {
            type: Number,
            default: 1
        }
    },
    setup(props) {
        const router = useRouter();
        const bbs = reactive({
          link1: '',
          link2: '',
          link3: '',
          link4: '',
          link5: '',
            ClickTab: (idx,event) => {
                if(bbs.idx < 1 && (bbs.tidx == 1 && idx > 1)){
                    alert("사보 전체를 먼저 게시해야 합니다.");
                    if(event){
                        event.preventDefault();
                    }else{
                        return false;
                    }
                }else if(bbs.idx < 1 && bbs.tidx > 1){
                    alert("사보 전체를 먼저 게시해야 합니다.");
                    router.push({ name:"ResourceManagement-NewsLetterManagement_tab1" });
                }else if(bbs.idx < 1 && bbs.tidx == 1){
                    if(event) event.preventDefault();
                }
            },
        });
        onMounted(() => {
            // Mounted
            bbs.idx = props.idx;
            bbs.tidx = props.tidx;
            bbs.mode = props.mode;

            for (let i = 1; i <= 5; i++) {
              const tab = bbs.mode == 1 ? '_tab' : 'View_tab';
              bbs[`link${i}`] = `ResourceManagement-NewsLetterManagement${tab}${i}-idx`;
            }

            const buttons = document.querySelectorAll('.tab-default');
            buttons.forEach(function(button) {
                button.classList.remove('on');
            });
            buttons[(bbs.tidx-1)].classList.add('on');

            bbs.ClickTab(bbs.tidx);
        });

        return {bbs};
    }
}
</script>
<style lang="scss" scoped>
  .newsLetterTab button{
    height: 55px;
    width: 12%;
  }
</style>